import { Auth as AmplifyAuth } from 'aws-amplify';
import { FunctionComponent, useEffect } from 'react';

import buildService from '@/services/build-service';
import configService from '@/services/config-service';

const config = configService.getConfig();

// If there is an error show the error page
if (config.error && buildService.isBrowser()) {
  window.location.replace(`/error/?e=${config.error}`);
}

const SignOutPage: FunctionComponent = () => {
  useEffect(() => {
    if (!config.error) {
      const promise = new Promise<void>((resolve, reject) => {
        // Attempt to get the current user
        AmplifyAuth.currentAuthenticatedUser()
          .then((user) => {
            // Then sign them out (current session)
            user.signOut(() => {
              resolve();
            });
          })
          .catch((e) => {
            reject(e);
          });
      });

      // Finally redirect to the provided url bn
      promise.finally(() => {
        window.location.replace(config.redirectUri);
      });
    }
  }, []);

  return <></>;
};

export default SignOutPage;
